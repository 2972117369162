import React from 'react';
import ContactForm from './ContactForm';
import './style.css';

function Home() {
    
    // Select all links with the scroll-link class
    const links = document.querySelectorAll('a[href^="#"]');

    // Loop through the links and add a click event listener to each
    links.forEach(link => {
      link.addEventListener('click', event => {
        // Prevent default link behavior
        event.preventDefault();

        // Get the target section by selecting the element with the corresponding id
        const target = document.querySelector(link.getAttribute('href'));

        // Smooth scroll to the target section
        target.scrollIntoView({
          behavior: 'smooth'
        });
      });
    });
  
  return (
    <div className="landing-page scroll">
        
      <header>
        <nav>
          <ul>
            <li><a href=".landing-page">Home</a></li>
            <li><a href="#about">About Us</a></li>
            <li><a href="#opportunities">Opportunities</a></li>
            {/* <li><a href="#models">Models</a></li> */}
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
      </header>
      <main className="scroll">
        <div className="logo">
          <img src="shibari.png" alt="Shibari Senpais Modeling" />
        </div>
        <section className="hero">
          <h1>Discover Your Inner Model</h1>
          <p>Join the Shibari Senpais family and start your modeling career today</p>
          <a href="#contact" className="cta-button">Apply Now</a>
        </section>
        <section id="about">
          <h2>About Shibari Senpais</h2>
          <p>Shibari Senpais is a modeling agency that specializes in Shibari or Japanese rope bondage modeling. The agency was founded in 2017 by a group of experienced Shibari artists who saw a need for a safe and professional space for Shibari models to showcase their artistry.

Shibari Senpais prides itself on providing a positive and inclusive environment that celebrates the beauty and creativity of Shibari. The agency's models are trained in safety techniques and are encouraged to explore their artistic expression through the medium of rope bondage.

The agency has worked with notable clients in the Shibari community, including renowned Shibari artists and photographers, as well as event organizers and promoters. Shibari Senpais' models have also been featured in numerous magazines, websites, and social media platforms, showcasing their exceptional talent and skill.

As an agency, Shibari Senpais is committed to upholding high ethical and professional standards, and to promoting the beauty and diversity of Shibari. They value the safety, dignity, and creativity of their models, and strive to create a supportive community that empowers and inspires.</p>
          <a href="#opportunities" className="cta-button">Learn More</a>
        </section>
        <section id="opportunities">
          <h2>Our Opportunities</h2>
          <ul className='opportunities-container'>
            <li className='opportunity'>
                <h3><u>Non-nude Modeling</u></h3>
                <b>Job Type:</b>Part-time, Contract
                <p>We are currently seeking models for non-nude modeling projects. The ideal candidate will be comfortable in front of the camera, possess a positive attitude, and have a strong work ethic.</p>

                <br />
<h4><u>Requirements:</u></h4>
<ul className="bullets">
<li>Comfortable with non-nude modeling assignments</li>
<li>Strong work ethic and ability to take direction</li>
<li>Excellent communication and interpersonal skills</li>
<li>Professional attitude and demeanor</li>
</ul>
<br />
<h4><u>Responsibilities:</u></h4>
<ul className='bullets'>

<li>Collaborate with photographers and creative teams to achieve desired looks and poses</li>
<li>Follow direction and guidance from photographers and creative teams</li>
<li>Maintain a professional and positive attitude during all modeling sessions</li>
<li>Participate in pre-shoot preparations, including wardrobe selection and hair/makeup styling</li>
<li>Adhere to all safety guidelines and protocols during modeling sessions</li>
</ul>
<br/>
If you are interested in this opportunity, please submit your resume, along with any relevant modeling experience or portfolio samples. We are an equal opportunity employer and welcome applicants of all backgrounds and experience levels.

            </li>
            
            <li className='opportunity'>
                <h3><u>Softcore Modeling</u></h3>
                <b>Job Type:</b>Part-time, Contract
                <p>We are looking for experienced softcore adult nude models to join our team! As a successful candidate, you must be comfortable posing for photographs in various states of undress and be willing to collaborate with photographers and stylists to create amazing images. You should also have a good eye for detail and be able to take direction to create the desired results.</p>
<br/>
<h4><u>Requirements:</u></h4>
<ul className='bullets'>
<li>
   Must be comfortable posing for photographs in various states of undress
  </li> 
  <li>
Must be able to take direction and collaborate with photographers and stylists

  </li>
  <li>
Must have a good eye for detail

  </li>

</ul>

<br/>

<h4><u>Responsibilities:</u></h4>
<ul className='bullets'>
  <li>Pose for photographs in various states of undress</li>
  <li>
Follow direction from photographers and stylists

  </li>
  <li>
Collaborate and communicate effectively with team members

  </li>
  <li>
Ensure that shoots are conducted in a professional and respectful manner

  </li>
</ul>
<br/>
If you are interested in this opportunity, please submit your resume, along with any relevant modeling experience or portfolio samples. We are an equal opportunity employer and welcome applicants of all backgrounds and experience levels.
            </li>
            <li className='opportunity'>
                <h3><u>Hardcore Modeling</u></h3>
                <b>Job Type:</b>Part-time, Contract
                <p>We are looking for experienced hardcore porn models to join our team! As a successful candidate, you must be comfortable performing sexual acts for photographs and videos and be willing to collaborate with directors and producers to create provocative images. You should also have a good eye for detail and be able to take direction and create the desired results.</p>
                <br />

<h4><u>Requirements:</u></h4>
<ul className='bullets'>
  <li>

Must be comfortable performing sexual acts for photographs and videos
  </li>
  <li>
    
Must be able to take direction and collaborate with directors and producers
  </li>
  <li>
Must have a good eye for detail
    
  </li>
</ul>
<br />
<h4><u>Responsibilities:</u></h4>
<ul className='bullets'>
  <li>
Perform sexual acts for photographs and videos

  </li>
  <li>
Follow direction from directors and producers
    
  </li>
  <li>
Collaborate and communicate effectively with team members
    
  </li>
  <li>
Ensure that shoots are conducted in a professional and respectful manner
    
  </li>
  <li>
Create stunning and provocative images
    
  </li>
</ul>
<br/>
If you are interested in this opportunity, please submit your resume, along with any relevant modeling experience or portfolio samples. We are an equal opportunity employer and welcome applicants of all backgrounds and experience levels.
            </li>
          </ul>
          {/* <a href="#" className="cta-button">See Our Portfolio</a> */}
        </section>
        {/* <section id="models">
          <h2>Meet Our Models</h2>
          <ul>
            <li>
              <img src="model1.jpg" alt="Model 1" />
              <h3>Model 1</h3>
              <p>Brief bio of Model 1.</p>
            </li>
            <li>
              <img src="model2.jpg" alt="Model 2" />
              <h3>Model 2</h3>
              <p>Brief bio of Model 2.</p>
            </li>
            <li>
              <img src="model3.jpg" alt="Model 3" />
              <h3>Model 3</h3>
              <p>Brief bio of Model 3.</p>
            </li>
          </ul>
          <a href="#" className="cta-button">Meet More Models</a>
        </section> */}
        {/* <section className="testimonials">
          <h2>What Our Models Say</h2>
          <blockquote>"Testimonial from satisfied model 1."</blockquote>
          <blockquote>"Testimonial from satisfied model 2."</blockquote>
          <a href="#" className="cta-button">Join Our Team</a>
        </section> */}
        <section id="contact">
          <h2>Contact Us</h2>
          {/* <form>
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" />
            // Add more input fields for email, message, etc.
          </form> */}
          <ContactForm />
        </section>
      </main>
      {/* <footer>
        <nav>
        <ul>
            <li><a href="#">Privacy Policy</a></li>
            <li><a href="#">Terms of Service</a></li>
            <li><a href="#">FAQ</a></li>
         </ul>
         </nav>
    <div className="social-media-links">
      <a href="#"><i className="fab fa-facebook"></i></a>
      <a href="#"><i className="fab fa-twitter"></i></a>
      <a href="#"><i className="fab fa-instagram"></i></a>
    </div>
  </footer> */}
</div>

);
}

export default Home;