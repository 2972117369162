const ContactForm = () => {

  return (
    <form target="_blank" action="https://formsubmit.co/recruiting@shibarisenpais.com" method="POST" encType="multipart/form-data">
          <input type="text" name="name" placeholder="Full Name" required />
          <input type="email" name="email" placeholder="Email Address" required />
          <input type="text" name="age" placeholder="Age" required />
          <input type="text" name="phone" placeholder="Phone Number" />
          <input type="text" name="height" placeholder="Height" />
          <input type="text" name="weight" placeholder="Weight" />
          <input type="text" name="measurements" placeholder="Measurements" />
          <input type="text" name="experience" placeholder="Experience" />
          <input type="text" name="availability" placeholder="Availability" />
          <input type="hidden" name="_next" value="http://www.shibarisenpais.com/thankyou" />
          <input type="hidden" name="_subject" value="Shibari Senpais Application" />
          <input type="text" name="_honey" id="honey" />
          <input type="hidden" name="_autoresponse" value="Thank you for submitting your application to become a model with Shibari Senpais. We appreciate your interest in our agency and will review your application as soon as possible. If you meet our requirements, we'll contact you soon to schedule an interview. In the meantime, please make sure to check your email regularly and keep an eye out for any updates from us. Thank you again for your interest in working with us. We wish you the best of luck with your modeling career! Sincerely, The Shibari Senpais Team" />
          <input type="file" name="attachment" accept="image/png, image/jpeg"/> 
      <textarea placeholder="Your Message" name="message" rows="10" required></textarea>
    <button type="submit" >Submit Form</button>
  </form>

  );
};

export default ContactForm;
